import React from 'react'
import "./HeroBanner.css"

// import HeroHeadshot from "../../images/cutout_fuzzy.png"
import HeroHeadshot from "../../images/cutout_fuzzy_eye_cutout.png"
import Glitch from '../Buttons/Trippy/Glitch/Glitch'
import OffCenterWhiteHamb from '../Buttons/Hamburgers/OffCenterWhiteHamb/OffCenterWhiteHamb'
import GlitchAbout from '../Buttons/Trippy/Glitch/GlitchAbout/GlitchAbout'
import GlitchWatch from '../Buttons/Trippy/Glitch/GlitchWatch/GlitchWatch'

export default function HeroBanner(props) {
  return (
    <div className='hero-banner__container'>
      <div className='image-center-style'>
        <div className='red-area' />
        <img className='hero-headshot' src={HeroHeadshot} alt="headshot" />
      </div>
        <div className={props.buttonHolder}>
          <Glitch setCardStatus={props.setCardStatus} setContactFormScreen={props.setContactFormScreen}  />
          <GlitchAbout setContactFormScreen={props.setContactFormScreen} setCardStatus={props.setCardStatus} aboutScreen={props.aboutScreen} setAboutScreen={props.setAboutScreen} />
          <GlitchWatch setCardStatus={props.setCardStatus} setContactFormScreen={props.setContactFormScreen}  />
        </div>
        <div className='button__holder-mobile'>
          <OffCenterWhiteHamb mobileMenu={props.mobileMenu} setMobileMenu={props.setMobileMenu} />
        </div>
    </div>
        )
      }
      