import React from 'react'
import "./WatchWindow.css"

export default function WatchWindow() {
  return (
    <div className='watch-window__container'>
      <h1>
      Coming Soon!
      </h1>
    </div>
  )
}
