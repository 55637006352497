import React from 'react'
import "./Glitch.css"
export default function Glitch(props) {
  return (
    <div>
      <button className='glitch-button' onClick={() => {
        props.setContactFormScreen("contact-form-show");
        props.setCardStatus("contact")
      }}>
        CONTACT
      </button>
    </div>
  )
}
