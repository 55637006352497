import React from 'react'
import InvertedInput from '../../Inputs/InvertedInput/InvertedInput'
import InvertedInputTextArea from '../../Inputs/InvertedInput/InvertedInputTextArea'
import BigRed from '../../Buttons/3DButton/BigReg/BigRed'

export default function ContactForm(props) {
  return (
    <form id="contactForm" style={{zIndex:1}} ref={props.form} onSubmit={props.sendEmail}>
    
          <InvertedInput type="text" name="from_name" required={true} placeholder="Name" />
          <InvertedInput type="email" name="reply_to" required={true} placeholder="Email" />
          <InvertedInputTextArea type="text" name="message" required={ false} placeholder="Message" cols="40" />
            <div style={{zIndex:"1", paddingTop:'20px'}}>
              <BigRed type="submit" value="Send" text="SEND"  />
            </div>
        </form>
  )
}
