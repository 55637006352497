import React from 'react'
import "./Home.css"
import HeroBanner from '../../Components/HeroBanner/HeroBanner'
import ContactModalCardBoarder from '../../Components/Modals/ContactModalCardBoarder/ContactModalCardBoarder'
import SlideAnimationSocials from '../../Components/Buttons/Socials/SlideAnimationSocials/SlideAnimationSocials'



export default function Home(props) {


  return (
    <div className='home-container'>
      <div className={props.socialHolder}>
        <SlideAnimationSocials />
      </div>
      <HeroBanner
        buttonHolder={props.buttonHolder}
        setContactFormScreen={props.setContactFormScreen}
        mobileMenu={props.mobileMenu}
        setMobileMenu={props.setMobileMenu}
        aboutScreen={props.aboutScreen}
        setAboutScreen={props.setAboutScreen}
        setCardStatus={props.setCardStatus}
      />
      <div className={props.contactFormScreen}>
        
        <ContactModalCardBoarder
        contactStatus={props.contactStatus}
        changeContactStatus={props.changeContactStatus}
        contactzIndex={props.contactzIndex}
        changeContactZindex={props.changeContactZindex}
        aboutStatus={props.aboutStatus}
        changeAboutStatus={props.changeAboutStatus}
        aboutzIndex={props.aboutzIndex}
                changeAboutZindex={props.changeAboutZindex}
        card={props.card}
        setCard={props.setCard}
        form={props.form}
        sendEmail={props.sendEmail}
        setContactFormScreen={props.setContactFormScreen}
        xOutHolder={props.xOutHolder}
        setXOutHolder={props.setXOutHolder}
          XoutOfCard={props.XoutOfCard}
          cardStatus={props.cardStatus}
          watchStatus={props.watchStatus}
        changeWatchStatus={props.changeWatchStatus}
        watchzIndex={props.watchzIndex}
        changeWatchZindex={props.changeWatchZindex}
        />
       
      </div>
    </div>
  )
}
