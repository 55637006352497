import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';

import './App.css';
import Home from './Screens/Home/Home';



function App() {
  const [card, setCard] = useState("card")
  const [cardStatus, setCardStatus]=useState("none")
  const [xOutHolder, setXOutHolder]=useState("x-out-holder")
  const [contactFormScreen, setContactFormScreen] = useState("contact-form-hide")
  const [aboutScreen, setAboutScreen]=useState("about-screen-hide")
  const [mobileMenu, setMobileMenu] = useState('mobile-menu__closed-first')
  const [buttonHolder, setButtonHolder] = useState('button-holder-start')
  const [socialHolder, setSocialHolder] = useState('social-holder-start')
  const [contactStatus, changeContactStatus] = useState("none")
  const[contactzIndex, changeContactZindex]=useState(0)
  const [aboutStatus, changeAboutStatus]=useState("none")
  const [aboutzIndex, changeAboutZindex] = useState(0)
  const [watchStatus, changeWatchStatus]=useState("none")
  const[watchzIndex, changeWatchZindex]=useState(0)
  const form = useRef();

  console.log('socialHolder', socialHolder)


  const CloseAllInCard = () => {
    changeContactStatus("none")
    changeContactZindex(0)
    changeAboutStatus("none")
    changeAboutZindex(0)
    changeWatchStatus("none")
    changeWatchZindex(0)

    console.log("CLOSED")
  }
  
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(process.env.REACT_APP_SERVICE_ID,process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY)
      .then((result) => {
        console.log(result.text);
        XoutOfCard();
        document.getElementById("contactForm").reset();
        // TY Message, Close the thing and all that...
      }, (error) => {
          console.log(error.text);
      });
  };

  const XoutOfCard = ()=>{
    setCard("card-shrink")
    CloseAllInCard();
    if (window.innerWidth < 816) {
      setMobileMenu("mobile-menu__opened")
    }
   

    setTimeout(() => {
      setXOutHolder("x-out-holder-out")
      CloseAllInCard()
    }, 500);

    setTimeout(() => {
      setContactFormScreen("contact-form-hide")
      setCardStatus("none")
      CloseAllInCard()
      setTimeout(() => {
        setCard("card")
        setXOutHolder("x-out-holder")
      }, 500);

    }, 1000);
  }

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    setButtonHolder("button__holder")
    setSocialHolder('social-holder')
  }, [buttonHolder])
  
    
  return (
    <div className="App">
      <Home
        card={card}
        setCard={setCard}
        form={form}
        sendEmail={sendEmail}
        xOutHolder={xOutHolder}
        setXOutHolder={setXOutHolder}
        XoutOfCard={XoutOfCard}
        setContactFormScreen={setContactFormScreen}
        contactFormScreen={contactFormScreen}
        mobileMenu={mobileMenu}
        setMobileMenu={setMobileMenu}
        buttonHolder={buttonHolder}
        socialHolder={socialHolder}
        aboutScreen={aboutScreen}
        setAboutScreen={setAboutScreen}
        cardStatus={cardStatus}
        setCardStatus={setCardStatus}
        contactStatus={contactStatus}
        changeContactStatus={changeContactStatus}
        contactzIndex={contactzIndex}
        changeContactZindex={changeContactZindex}
        aboutStatus={aboutStatus}
        changeAboutStatus={changeAboutStatus}
        aboutzIndex={aboutzIndex}
        changeAboutZindex={changeAboutZindex}
        watchStatus={watchStatus}
        changeWatchStatus={changeWatchStatus}
        watchzIndex={watchzIndex}
        changeWatchZindex={changeWatchZindex}
      />
      <div className={mobileMenu}>
        <h1 onClick={
          () => {
            setContactFormScreen("contact-form-show");
            setCardStatus("contact")
            setMobileMenu("mobile-menu__closed")
          }
          } 
            style={{ color: 'white' }}>CONTACT</h1>
        <h1
        onClick={
          () => {
            setContactFormScreen("contact-form-show");
            setCardStatus("about")
            setMobileMenu("mobile-menu__closed")
          }
          } 
          style={{ color: 'white' }}>ABOUT</h1>
          <h1
          onClick={
            () => {
              setContactFormScreen("contact-form-show");
              setCardStatus("watch")
              setMobileMenu("mobile-menu__closed")
            }
            } 
            style={{ color: 'white' }}>WATCH</h1>
      </div>
    </div>
  );
}

export default App;
