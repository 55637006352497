import React from 'react'
import "./GlitchWatch.css"
export default function GlitchWatch(props) {
  return (
    <div>
      <button className='glitch-watch-button'
        onClick={() => {
          props.setContactFormScreen("contact-form-show");
          props.setCardStatus("watch")
        }
        }
      >
        WATCH
      </button>
    </div>
  )
}
