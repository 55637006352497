import React from 'react'
import "./BigRed.css"

export default function BigRed(props) {
  return (
    <div>
    <button type={props.submit} value={props.send} className="button">
        <div className="button-top">{props.text }</div>
    <div className="button-bottom"></div>
        <div className="button-base"></div>
        </button>
    </div>
  )
}
