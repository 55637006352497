import React from 'react'
import "./InvertedInput.css"

export default function InvertedInput(props) {
  return (
    <div className='inverted-input-holder'>
      <input
        placeholder={props.placeholder}
        className="input"
        name={props.name}
        type={props.type}
        cols={props.cols}
        rows={props.rows}
        required={props.required}
      />
    </div>
  )
}

// <input placeholder="Username" class="input" name="text" type="text"></input>