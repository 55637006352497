import React from 'react'
import "./GlitchAbout.css"
export default function GlitchAbout(props) {
  return (
    <div>
      <button className='glitch-about-button' onClick={() =>
      {
        props.setContactFormScreen("contact-form-show");
        props.setCardStatus("about")
      }
      }>
        ABOUT
      </button>
    </div>
  )
}
