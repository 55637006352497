import React from 'react'
import "./AboutText.css"

export default function AboutText() {
  return (
    <div className='about-text'>
      <p>
        Christina Balonek is a Rochester, NY native currently touring in The Tape Face Show. </p>
      <p>
        Previous Rochester stage credits include:
      </p>
      <ul>
      <li>
      Babe in Crimes in the Heart and Debbie in Debbie Does Dallas
      at Blackfriars Theater.
      </li>  
      </ul>
      <p>
        Currently performing in Las Vegas, Strip and Off-Strip credits include:</p>
      <ul>
        <li>
        Shelly in Evil Dead the Musical at Planet Hollywood,
        </li><br/>
        <li>
          Debbie in Debbie Does Dallas at the Onyx Theater
          </li><br/>
        <li>
        Origination of the role of Phylis Vanillis in The Tape Face Show at the Flamingo Hotel & Casino Harrah's Hotel & Casino and currently at MGM Grands underground theater.
        </li> 
        </ul>
        <p>
          Film/Online credits include:
          </p>
      <ul>
        <li>
          bridesmaid: After 2014
          </li>  
        <li>
          (Kayleigh Mackinninny) in Pauly Shores Election Online Livestream.
          </li>
        <li>
        Her favorite film credit remains when she was a contestant on Slime Time Live in 1999. She won a bike!
        </li>  
      </ul>
      
    </div>
  )
}
