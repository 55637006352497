import React from 'react'
import "./InvertedInput.css"

export default function InvertedInputTextArea(props) {
  return (
    <div className='inverted-input-holder'>
      <textarea
        placeholder={props.placeholder}
        className="input"
        name={props.name}
        type={props.type}
        cols={props.cols}
        rows={props.rows}
      />
    </div>
  )
}
