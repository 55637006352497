import React, {useEffect} from 'react'
import "./ContactModalCardBoarder.css"
import ContactForm from '../../Forms/ContactForm/ContactForm'
import AboutText from '../../AboutText/AboutText'
import WatchWindow from '../../WatchWindow/WatchWindow'

export default function ContactModalCardBoarder(props) {
  
  useEffect(() => {
    if (props.cardStatus==="none") {
      console.log("none")
    } else if (props.cardStatus === "contact") {
      console.log("contact")
      props.changeContactStatus("inline")
      props.changeContactZindex(999)
    } else if (props.cardStatus === "watch") {
      console.log("watch")
      props.changeWatchStatus("inline")
      props.changeWatchZindex(999)
    } else if (props.cardStatus === "about") {
      console.log("about")
      props.changeAboutStatus("inline")
      props.changeAboutZindex(999)
      
    }
    else {
      props.setCardStatus('none')
    }
  }, [props])


  
  return (
    <div className='contact_modal_card_boarder__holder'>
      <div className={props.xOutHolder}>
        <h1 onClick={props.XoutOfCard}>X</h1>
      </div>
        <div className={props.card}>
          <div style={{display:props.aboutStatus, zIndex:props.aboutzIndex}}>
            <AboutText />
          </div>
          <div  style={{display:props.contactStatus, zIndex:props.contactzIndex}}>
            <ContactForm
              form={props.form} 
              sendEmail={props.sendEmail} 
              />  
        </div>
        <div  style={{display:props.watchStatus, zIndex:props.watchzIndex}}>
            <WatchWindow 
              />  
          </div>
       
      </div>
    </div>
  )
}

// <div  style={{display:props.contactStatus, zIndex:props.contactzIndex}}> /// CHANGE TO WATCH 
//             <WatchWindow />
//           </div>